import React, { useState } from "react"
import styled from "styled-components"
import Button from "../components/Button"
import { useForm } from "react-hook-form"

const Form = styled.div`
  margin: calc(var(--spacing) * 2) 0;
  @media (min-width: 1200px) {
    margin: calc(var(--spacing) * 4) 0;
  }
`

const FormLabel = styled.label`
  font-family: var(--serif);
  font-size: var(--h5);
  margin-bottom: var(--spacing);
  display: inline-block;
`

const FormInput = styled.input`
  height: 50px;
  width: 100%;
  margin-bottom: 0rem;
  font-size: var(--para);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
`

const Newsletter = () => {
  const [submitted, setSubmitted] = useState(false)
  const { register, handleSubmit, setError, reset } = useForm()
  const onSubmit = async (data) => {
    try {
      fetch(
         "https://1g0bn8wje0.execute-api.eu-west-1.amazonaws.com/Prod/submitForm",
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      setSubmitted(true)
      reset()
    } catch (error) {
      setError(
        "submit",
        "submitError",
        `Oops! There seems to be an issue! ${error.message}`
      )
    }
  }

  const showThankYou = (
    <div>
      <p>Thank you! You are now subscribed to our newsletter.</p>
    </div>
  )
  const showForm = (
    <form name="contact" onSubmit={handleSubmit(onSubmit)}>
      <p>
        <FormLabel for="email">Sign up for our newsletter</FormLabel>
        <FormInput
          type="email"
          name="email"
          id="email"
          {...register("email", {
            required: "Your email address is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Please enter a valid email address",
            },
          })}
        />
      </p>
      <p>
        <Button className="btn-link" text="Submit" type="submit" />
      </p>
    </form>
  )

  return (
    <>
      <Form>{submitted ? showThankYou : showForm}</Form>
    </>
  )
}

export default Newsletter
