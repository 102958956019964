import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/SEO"
import BlogItem from "../components/BlogItem"
import styled from "styled-components"
import Banner from "../components/Banner"
import Button from "../components/Button"
import Newsletter from "../components/Newsletter"

//@media (max-width: 768px) {


const Neutral = styled.div`
  width: 90vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: -5vw;
}`

// linear-gradient(to right, #00291c, #084a35, #00291c);
// background-image: linear-gradient(45deg, rgba(0,41,28,1) 0%, rgba(8,74,53,1) 20%, rgba(0,41,28,1) 100%);

const Container = styled.div`
  height: 80vh;
  width: 100vw;
  background-image: linear-gradient(45deg, #00291c 0%, #084a35 20%, #00291c 100%);
  padding: 15rem 0rem 0rem 0rem;
  h1 {
    color: #fff;
    padding: 0rem 2rem 0rem 2rem;
    max-width: 1100px;
  }
  h3 {
    color: #fff;
    padding: 0rem 2rem 0rem 2rem;
    max-width: 1100px;
  }
  @media (max-width: 768px) {
    padding: 10rem 0rem 0rem 0rem;
    h1 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1rem;
    }
`


const ButtonNew = styled.button`
  border: none;
  background-color: transparent;
  font-size: var(--h5);
  margin: 0rem 0rem 0rem 2rem;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  font-family: var(--serif);
  color: #fff;
  text-decoration: none;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 0.1rem;
    width: 100%;
    background-color: #fff;
    left: 0;
    bottom: -0.25rem;
    opacity: 1;
    transition: opacity var(--transSlow);
  }

  &:hover,
  &:focus {
    cursor: pointer;

    &:after {
      opacity: 0.15;
    }
  }

  &:visited {
    text-decoration: none;
  }
`



const Landing = styled.div`
  height: 90vh;
  margin: 27vh 0rem 0rem -7rem;
  @media (max-width: 1200px) {
    margin: 20vh 0rem 0rem 0rem;
  }
  @media (max-width: 768px) {
    margin: 20vh 0rem 0rem 0rem;
    h1 {
      font-size: 1.6rem;
    }
    h3 {
      font-size: 1.1rem;
    }
  }
`

const FeaturedItems = styled.h4`
  font-size: var(--h5);
  color: var(--primaryColor);
`

const HomePage = ({ data }) => {
  const { BlogPostQuery } = data

  return (
    <>
      <Seo />
      <Neutral>
      <Container>.
      <h1>Fintech for sustainability and climate action</h1>
        <h3>We work with fintechs, financial institutions, governments and NGOs to build tomorrow’s green finance organisations and innovations.</h3>
        <Link href="/contact">
          {/* <Button className="btn-link" to="/contact" text="Contact us" /> */}
          <ButtonNew>Contact us</ButtonNew>
        </Link>
      </Container>
      </Neutral>
      {/* <Landing>
        <h1>To inspire and accelerate green fintech for sustainability and climate change solutions</h1>
        <h3>We work with fintechs, financial institutions, governments and NGOs to build tomorrow’s green finance organisations and innovations.</h3>
        <Link href="/contact">
          <Button className="btn-link" to="/contact" text="Contact us" />
        </Link>
      </Landing> */}
      <Banner content="Our latest blog posts." />
      <FeaturedItems>{BlogPostQuery.totalCount} Featured Posts</FeaturedItems>
      {BlogPostQuery.edges.map(({ node }, index) => (
        <BlogItem nodeObj={node} index={index} />
      ))}
      <Newsletter />
    </>
  )
}

export default HomePage

export const query = graphql`
  {
    BlogPostQuery: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM DD, YY")
            path
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                )
              }
            }
            featuredImageAlt
          }
          excerpt
        }
      }
    }
  }
`
